import { ChangellyClaim, LetsexchangeClaim, TProviderName } from '../../../api/exchanges-api/classes';

type fieldKey =
  | 'providerID'
  | 'claimStatus'
  | 'amountFrom'
  | 'amountTo'
  | 'amountExpectedFrom'
  | 'amountExpectedTo'
  | 'payinAddress'
  | 'payinExtraId'
  | 'payoutAddress'
  | 'payoutExtraId'
  | 'refundAddress'
  | 'refundExtraId'
  | 'payInHash'
  | 'payOutHash'
  | 'refundHash';

type ProviderClaim = ChangellyClaim | LetsexchangeClaim;

function getProviderData<T extends ProviderClaim>(data: T): T {
  return data;
}

export function getProvidersDataByKey(providerName: TProviderName, fieldKey: fieldKey, data: any): string {
  switch (fieldKey) {
    case 'providerID': {
      let value;
      if (data !== null) {
        switch (providerName) {
          case 'CHANGELLY': {
            const dataByKey = getProviderData<ChangellyClaim>(data).changelly_id; // .somekey;
            value = dataByKey ? dataByKey : 'null from CHANGELLY';
            break;
          }
          case 'LETS_EXCHANGE': {
            const dataByKey = getProviderData<LetsexchangeClaim>(data).transaction_id; // .somekey;
            value = dataByKey ? dataByKey : 'null from LETS_EXCHANGE';
            break;
          }
          default: {
            value = 'No exist provider';
            break;
          }
        }
      } else {
        value = 'No Data';
      }
      return value;
    }
    case 'amountFrom': {
      let value;
      if (data !== null) {
        switch (providerName) {
          case 'CHANGELLY': {
            const dataByKey = getProviderData<ChangellyClaim>(data).amount_from; // .somekey;
            value = dataByKey ? dataByKey : 'null from CHANGELLY';
            break;
          }
          case 'LETS_EXCHANGE': {
            const dataByKey = getProviderData<LetsexchangeClaim>(data).real_deposit_amount; // .somekey;
            value = dataByKey ? dataByKey : 'null from LETS_EXCHANGE';
            break;
          }
          default: {
            value = 'No exist provider';
            break;
          }
        }
      } else {
        value = 'No Data';
      }
      return value;
    }
    case 'amountTo': {
      let value;
      if (data !== null) {
        switch (providerName) {
          case 'CHANGELLY': {
            const dataByKey = getProviderData<ChangellyClaim>(data).amount_to; // .somekey;
            value = dataByKey ? dataByKey : 'null from CHANGELLY';
            break;
          }
          case 'LETS_EXCHANGE': {
            const dataByKey = getProviderData<LetsexchangeClaim>(data).real_withdrawal_amount; // .somekey;
            value = dataByKey ? dataByKey : 'null from LETS_EXCHANGE';
            break;
          }
          default: {
            value = 'No exist provider';
            break;
          }
        }
      } else {
        value = 'No Data';
      }
      return value;
    }
    case 'amountExpectedFrom': {
      let value;
      if (data !== null) {
        switch (providerName) {
          case 'CHANGELLY': {
            const dataByKey = getProviderData<ChangellyClaim>(data).amount_expected_from; // .somekey;
            value = dataByKey ? dataByKey : 'null from CHANGELLY';
            break;
          }
          case 'LETS_EXCHANGE': {
            const dataByKey = getProviderData<LetsexchangeClaim>(data).deposit_amount; // .somekey;
            value = dataByKey ? dataByKey : 'null from LETS_EXCHANGE';
            break;
          }
          default: {
            value = 'No exist provider';
            break;
          }
        }
      } else {
        value = 'No Data';
      }
      return value;
    }
    case 'amountExpectedTo': {
      let value;
      if (data !== null) {
        switch (providerName) {
          case 'CHANGELLY': {
            const dataByKey = getProviderData<ChangellyClaim>(data).amount_expected_to; // .somekey;
            value = dataByKey ? dataByKey : 'null from CHANGELLY';
            break;
          }
          case 'LETS_EXCHANGE': {
            const dataByKey = getProviderData<LetsexchangeClaim>(data).withdrawal_amount; // .somekey;
            value = dataByKey ? dataByKey : 'null from LETS_EXCHANGE';
            break;
          }
          default: {
            value = 'No exist provider';
            break;
          }
        }
      } else {
        value = 'No Data';
      }
      return value;
    }
    case 'payinAddress': {
      let value;
      if (data !== null) {
        switch (providerName) {
          case 'CHANGELLY': {
            const dataByKey = getProviderData<ChangellyClaim>(data).payin_address; // .somekey;
            value = dataByKey ? dataByKey : 'null from CHANGELLY';
            break;
          }
          case 'LETS_EXCHANGE': {
            const dataByKey = getProviderData<LetsexchangeClaim>(data).deposit; // .somekey;
            value = dataByKey ? dataByKey : 'null from LETS_EXCHANGE';
            break;
          }
          default: {
            value = 'No exist provider';
            break;
          }
        }
      } else {
        value = 'No Data';
      }
      return value;
    }
    case 'payinExtraId': {
      let value;
      if (data !== null) {
        switch (providerName) {
          case 'CHANGELLY': {
            const dataByKey = getProviderData<ChangellyClaim>(data).payin_extra_id; // .somekey;
            value = dataByKey ? dataByKey : 'null from CHANGELLY';
            break;
          }
          case 'LETS_EXCHANGE': {
            const dataByKey = getProviderData<LetsexchangeClaim>(data).deposit_extra_id; // .somekey;
            value = dataByKey ? dataByKey : 'null from LETS_EXCHANGE';
            break;
          }
          default: {
            value = 'No exist provider';
            break;
          }
        }
      } else {
        value = 'No Data';
      }
      return value;
    }
    case 'payoutAddress': {
      let value;
      if (data !== null) {
        switch (providerName) {
          case 'CHANGELLY': {
            const dataByKey = getProviderData<ChangellyClaim>(data).payout_address; // .somekey;
            value = dataByKey ? dataByKey : 'null from CHANGELLY';
            break;
          }
          case 'LETS_EXCHANGE': {
            const dataByKey = getProviderData<LetsexchangeClaim>(data).withdrawal; // .somekey;
            value = dataByKey ? dataByKey : 'null from LETS_EXCHANGE';
            break;
          }
          default: {
            value = 'No exist provider';
            break;
          }
        }
      } else {
        value = 'No Data';
      }
      return value;
    }
    case 'payoutExtraId': {
      let value;
      if (data !== null) {
        switch (providerName) {
          case 'CHANGELLY': {
            const dataByKey = getProviderData<ChangellyClaim>(data).payout_extra_id; // .somekey;
            value = dataByKey ? dataByKey : 'null from CHANGELLY';
            break;
          }
          case 'LETS_EXCHANGE': {
            const dataByKey = getProviderData<LetsexchangeClaim>(data).withdrawal_extra_id; // .somekey;
            value = dataByKey ? dataByKey : 'null from LETS_EXCHANGE';
            break;
          }
          default: {
            value = 'No exist provider';
            break;
          }
        }
      } else {
        value = 'No Data';
      }
      return value;
    }
    case 'refundAddress': {
      let value;
      if (data !== null) {
        switch (providerName) {
          case 'CHANGELLY': {
            const dataByKey = getProviderData<ChangellyClaim>(data).refund_address; // .somekey;
            value = dataByKey ? dataByKey : 'null from CHANGELLY';
            break;
          }
          case 'LETS_EXCHANGE': {
            const dataByKey = getProviderData<LetsexchangeClaim>(data).return; // .somekey;
            value = dataByKey ? dataByKey : 'null from LETS_EXCHANGE';
            break;
          }
          default: {
            value = 'No exist provider';
            break;
          }
        }
      } else {
        value = 'No Data';
      }
      return value;
    }
    case 'refundExtraId': {
      let value;
      if (data !== null) {
        switch (providerName) {
          case 'CHANGELLY': {
            const dataByKey = getProviderData<ChangellyClaim>(data).refund_extra_id; // .somekey;
            value = dataByKey ? dataByKey : 'null from CHANGELLY';
            break;
          }
          case 'LETS_EXCHANGE': {
            const dataByKey = getProviderData<LetsexchangeClaim>(data).return_extra_id; // .somekey;
            value = dataByKey ? dataByKey : 'null from LETS_EXCHANGE';
            break;
          }
          default: {
            value = 'No exist provider';
            break;
          }
        }
      } else {
        value = 'No Data';
      }
      return value;
    }
    case 'payInHash': {
      let value;
      if (data !== null) {
        switch (providerName) {
          case 'CHANGELLY': {
            const dataByKey = getProviderData<ChangellyClaim>(data).payin_hash; // .somekey;
            value = dataByKey ? dataByKey : 'null from CHANGELLY';
            break;
          }
          case 'LETS_EXCHANGE': {
            const dataByKey = getProviderData<LetsexchangeClaim>(data).hash_in; // .somekey;
            value = dataByKey ? dataByKey : 'null from LETS_EXCHANGE';
            break;
          }
          default: {
            value = 'No exist provider';
            break;
          }
        }
      } else {
        value = 'No Data';
      }
      return value;
    }
    case 'payOutHash': {
      let value;
      if (data !== null) {
        switch (providerName) {
          case 'CHANGELLY': {
            const dataByKey = getProviderData<ChangellyClaim>(data).payout_hash; // .somekey;
            value = dataByKey ? dataByKey : 'null from CHANGELLY';
            break;
          }
          case 'LETS_EXCHANGE': {
            const dataByKey = getProviderData<LetsexchangeClaim>(data).hash_out; // .somekey;
            value = dataByKey ? dataByKey : 'null from LETS_EXCHANGE';
            break;
          }
          default: {
            value = 'No exist provider';
            break;
          }
        }
      } else {
        value = 'No Data';
      }
      return value;
    }
    case 'refundHash': {
      let value;
      if (data !== null) {
        switch (providerName) {
          case 'CHANGELLY': {
            const dataByKey = getProviderData<ChangellyClaim>(data).refund_hash; // .somekey;
            value = dataByKey ? dataByKey : 'null from CHANGELLY';
            break;
          }
          case 'LETS_EXCHANGE': {
            const dataByKey = getProviderData<LetsexchangeClaim>(data).return_hash; // .somekey;
            value = dataByKey ? dataByKey : 'null from LETS_EXCHANGE';
            break;
          }
          default: {
            value = 'No exist provider';
            break;
          }
        }
      } else {
        value = 'No Data';
      }
      return value;
    }

    default: {
      return 'Data not found';
    }
  }
}
