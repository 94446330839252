import { observer } from 'mobx-react-lite';
import { Select } from 'antd';

export type TOption<T> = {
  label: string;
  value: T;
};

type Props<T> = {
  options: TOption<T>[];
  onSelect: (value: T) => void;
  width?: string;
};

const View = <T extends unknown>({ options, onSelect, width = '150px' }: Props<T>) => {
  const handleChange = (value: T[]) => {
    const selectedValue = value[0];
    onSelect(selectedValue);
  };

  const filterOption = (input: string, option?: TOption<T>) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Select
      style={{ width: width }}
      mode="multiple"
      maxCount={1}
      showSearch
      onChange={handleChange}
      filterOption={filterOption}
      options={options}
    />
  );
};

export const SelectStaticFilter = observer(View);