import { ExPairsDataType } from '../../../../../../../api/exchangePair-api/classes';
import { ChangellyClaim, LetsexchangeClaim } from '../../../../../../../api/exchanges-api/classes';
import { getProvidersDataByKey } from '../../../../../../../common/helpers/providers';
import { TProviders } from '../../../../../../../common/types';

export enum CurrencyType {
  EXPECTED = 'expected',
  REAL = 'real',
}

export const getCurrency = (
  type: CurrencyType,
  claimData: ChangellyClaim | LetsexchangeClaim,
  exchange_pair: ExPairsDataType,
  providerName: TProviders
) => {
  //  getField function

  const amountExpectedFrom = getProvidersDataByKey(providerName, 'amountExpectedFrom', claimData);
  const amountExpectedTo = getProvidersDataByKey(providerName, 'amountExpectedTo', claimData);
  const amountFrom = getProvidersDataByKey(providerName, 'amountFrom', claimData);
  const amountTo = getProvidersDataByKey(providerName, 'amountTo', claimData);

  if (type === CurrencyType.EXPECTED) {
    return (
      `${amountExpectedFrom} ` +
      `${exchange_pair.instrument_from.coin.ticker} (${exchange_pair.instrument_from.network.ticker})` +
      `  =  ` +
      `${amountExpectedTo} ` +
      `${exchange_pair.instrument_to.coin.ticker} (${exchange_pair.instrument_to.network.ticker})`
    );
  }
  if (type === CurrencyType.REAL) {
    return (
      `${amountFrom} ` +
      `${exchange_pair.instrument_from.coin.ticker} (${exchange_pair.instrument_from.network.ticker})` +
      `  =  ` +
      `${amountTo} ` +
      `${exchange_pair.instrument_to.coin.ticker} (${exchange_pair.instrument_to.network.ticker})`
    );
  }
};
