import { useEffect, useReducer, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Flex, Layout, Table } from 'antd';
import { Header, Content } from 'antd/es/layout/layout';

import { columns } from './columns';
import { expandedRowRender } from './components/ExpandedTable';
import { Loader } from '../../common/components/Loader';

import style from './styles/style.module.scss';

import { ClearingDataType } from '../../api/clearing-api/classes';
import { ActionValueTypes, IActionTypes, filterReducer, initialFilters } from './filterReducer';
import { FilterBar } from './components/FilterBar';
import { useModulesContext } from '../../context/ModulesContextProvider';
import { AtLeastOne } from '../../common/types';

const View = () => {
  const { ClearingStore } = useModulesContext();

  const [loading, setLoading] = useState(true);

  const [filters, dispatch] = useReducer(filterReducer, initialFilters);

  useEffect(() => {
    const props = {
      ...filters,
    };
    ClearingStore.getClearingData(props).then(() => setLoading(false));
  }, [ClearingStore, filters]);

  function handleSelectFilter(value: AtLeastOne<ActionValueTypes>, actionKey: IActionTypes) {
    dispatch({
      type: actionKey,
      value: value,
    });
  }

  return (
    <>
      <Layout className={style.layout}>
        <Header className={style.layout__header}>
          <h1>Клиринг</h1>
        </Header>
        <Content className={style.layout__content}>
          <Flex vertical gap={10}>
            <Flex vertical gap={5}>
              <FilterBar filters={filters} onFilter={handleSelectFilter} />
            </Flex>
            {loading ? (
              <Loader />
            ) : (
              <Table
                rowKey={(record: ClearingDataType) => `${record.instrumentFrom.id}+${record.instrumentTo.id}`}
                dataSource={ClearingStore.data}
                bordered
                scroll={{ x: 2000 }}
                columns={columns({ ...filters })}
                expandable={{ expandedRowRender }}
              />
            )}
          </Flex>
        </Content>
      </Layout>
    </>
  );
};

export const Clearing = observer(View);
