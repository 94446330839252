export type TStatus = 'ALL' | 'ACTIVE' | 'INACTIVE';

export type TFileExtentions = 'json';

export const providers = ['CHANGELLY', 'LETS_EXCHANGE'] as const;
export type TProviders = (typeof providers)[number];

export const rateTypes = ['MARKET', 'FIXED'] as const;
export type TRateTypes = (typeof rateTypes)[number];

export type AtLeastOne<T> = {
  [K in keyof T]: Required<Pick<T, K>> & Partial<Pick<T, Exclude<keyof T, K>>>;
}[keyof T];

export const claimStatusesArr = [
  'NEW',
  'WAIT_DEPOSIT',
  'CONFIRM',
  'EXCHANGE',
  'SEND',
  'FINISH',
  'FAIL',
  'REFUND',
  'HOLD',
  'EXPIRE',
] as const;
export type TClaimStatus = (typeof claimStatusesArr)[number];
